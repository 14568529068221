import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './Login';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Bag from './Bag'
import BagOne from './BagOne'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<App />} />
          <Route path="/u/27c864d3-f24d-4cff-b7d3-c764ebea402d" element={<BagOne />} />
          <Route path='/u/19850335-b81d-4dc1-96a7-037827fe9e98' element={<Bag />} />
        </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

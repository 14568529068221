import React from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Image,
  Message,
} from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import snackpurse from "./snackpurse.svg";
import pantrystash from "./pantrystash.svg";

import logo from "./logo.svg";
const LoginForm = () => (
  <Container>
    <Grid
      textAlign="center"
      style={{ height: "70vh", minHeight: "450px" }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" textAlign="center">
          <Image className="logo" src={logo} />
        </Header>
        <Form size="large">
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="E-mail address"
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
          />
          <Button color="black" fluid size="large">
            Submit
          </Button>
        </Form>
        <Message>
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="Enter email to be added to invitation list"
          />
        </Message>
      </Grid.Column>
    </Grid>
    <div className="partnercontainer">
      <div className="title">IN PARTNERSHIP WITH </div>
      <div>
        <div className="partner">
          <a href="https://snackpurse.com">
            <Image src={snackpurse} />
            <br />
            SnackPurse.com
          </a>
        </div>
        <div className="partner">
          <a href="https://pantrystash.com">
            <Image src={pantrystash} />
            <br />
            PantryStash.com
          </a>
        </div>
      </div>
    </div>
  </Container>
);

export default LoginForm;

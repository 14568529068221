

import frame from './bag2.svg';
import './App.css';


function File() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={frame} className="App-logo" alt="logo" />
    Bag 2
        <a
          className="App-link"
          href="/"
    

        >
         /u/27c864d3-f24d-4cff-b7d3-c764ebea402d
        </a>
      </header>
    </div>
  );
}

export default File;

import frame from "./bag1.svg";
import "./App.css";
import "./bag.css";

let listOfThings = [
  {
    sno: 1,
    name: "Sleeping Mat, Self-Inflating, OD Green",
    NSN: "8465-01-393-6515",
    qty: 1,
    remarks: "With Rucksack",
    additionalRemarks: "***************",
  },
  {
    sno: 2,
    name: "Assault Pack",
    NSN: "8465-01-524-5250",
    qty: 1,
    remarks: "In black bag3",
    additionalRemarks: "Digital Camo Fighting Load Carrier (FLC)",
  },
  {
    sno: 4,
    name: "1qt canteen general purpose pouch",
    NSN: "8465-01-525-0585",
    qty: 2,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 5,
    name: "Triple Ammo Magazine Pouch 5.56mm",
    NSN: "8465-01-525-0598",
    qty: 2,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 6,
    name: "Waist Pack",
    NSN: "8465-01-524-7263",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 7,
    name: "Hand Grenade Pouch",
    NSN: "8465-01-525-0589",
    qty: 2,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 8,
    name: "Flash Bang Grenade Pouch",
    NSN: "8465-01-524-7324",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 9,
    name: "BANDOLEER Ammunition Pouch",
    NSN: "8465-01-524-7309",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 10,
    name: "3 Double Mag Pouch Digital Camo",
    NSN: "8465-01-525-0606",
    qty: 3,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 11,
    name: "MOLLE Load Lifter Attachment Straps",
    NSN: "8465-01-580-1666",
    qty: 2,
    remarks: "PART OF RUCKSACK PROBABLY",
    additionalRemarks: "***************",
  },
  {
    sno: 12,
    name: "MOLLE MultiCam (OCP) Large Rucksack",
    NSN: "8465-01-580-1560",
    qty: 1,
    remarks: "PART OF RUCKSACK PROBABLY",
    additionalRemarks: "***************",
  },
  {
    sno: 13,
    name: "MOLLE Sustainment Pouch",
    NSN: "8465-01-580-1563",
    qty: 2,
    remarks: "PART OF RUCKSACK PROBABLY",
    additionalRemarks: "***************",
  },
  {
    sno: 14,
    name: "Shoulder Straps",
    NSN: "8465-01-580-1664",
    qty: 1,
    remarks: "PART OF RUCKSACK PROBABLY",
    additionalRemarks: "***************",
  },
  {
    sno: 15,
    name: "Rucksack Plastic Molded Frame",
    NSN: "8465-01-519-6440",
    qty: 1,
    remarks: "PART OF RUCKSACK PROBABLY",
    additionalRemarks: "***************",
  },
  {
    sno: 16,
    name: "Molded Waist Belt",
    NSN: "8465-01-580-1575",
    qty: 1,
    remarks: "PART OF RUCKSACK PROBABLY",
    additionalRemarks: "***************",
  },
  {
    sno: 17,
    name: "SMALL Stuff Sack",
    NSN: "8465-01-547-2656",
    qty: 1,
    remarks: "?",
    additionalRemarks: "***************",
  },
  {
    sno: 18,
    name: "Large Stuff Sack",
    NSN: "8465-01-547-2670",
    qty: 1,
    remarks: "?",
    additionalRemarks: "***************",
  },
  {
    sno: 19,
    name: "Bag Barrack (Laundry Bag)",
    NSN: "8465-00-530-3692",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 20,
    name: "canteen",
    NSN: "8465-01-115-0026",
    qty: 2,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 21,
    name: "cup water canteen",
    NSN: "8465-00-165-6838",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 22,
    name: "individual insert",
    NSN: "8465-01-531-3147",
    qty: 1,
    remarks: "NEVER RECEIVED IT - MARKED AS IN KIT",
    additionalRemarks: "***************",
  },
  {
    sno: 23,
    name: "pouch individual",
    NSN: "8465-01-531-3647",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 24,
    name: "tactical assault protective pads",
    NSN: "8465-01-663-7517",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 25,
    name: "knee pads",
    NSN: "8465-01-663-7518",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 26,
    name: "hood cold weather",
    NSN: "8415-01-526-8790",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 27,
    name: "safety glasses EYE PRO",
    NSN: "4240-01-527-4051",
    qty: 1,
    remarks: "Pending to find",
    additionalRemarks: "***************",
  },
  {
    sno: 28,
    name: "goggles",
    NSN: "4240-01-592-8619",
    qty: 1,
    remarks: "Pending to find",
    additionalRemarks: "***************",
  },
  {
    sno: 29,
    name: "helmet advncd (ACH)",
    NSN: "8470-01-529-6344",
    qty: 1,
    remarks: "Got it",
    additionalRemarks: "***************",
  },
  {
    sno: 30,
    name: "Cover helmet",
    NSN: "8415-01-641-7119",
    qty: 1,
    remarks: "Got it",
    additionalRemarks: "***************",
  },
  {
    sno: 31,
    name: "jacket cold weather",
    NSN: "8415-01-641-1643",
    qty: 1,
    remarks: "Vaccum Bag",
    additionalRemarks: "***************",
  },
  {
    sno: 32,
    name: "trousers extreme cold weather",
    NSN: "8415-01-641-1801",
    qty: 1,
    remarks: "Vaccum Bag",
    additionalRemarks: "***************",
  },
  {
    sno: 33,
    name: "Tarpaulin",
    NSN: "8340-01-600-4809",
    qty: 1,
    remarks: "B1",
    additionalRemarks: "***************",
  },
  {
    sno: 34,
    name: "liner wet weather",
    NSN: "8405-01-547-2559",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 35,
    name: "jacket cold weather",
    NSN: "8415-01-641-1729",
    qty: 1,
    remarks: "Vaccum Bag",
    additionalRemarks: "***************",
  },
  {
    sno: 36,
    name: "bag clothing waterproof",
    NSN: "8465-00-261-6909",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 37,
    name: "gloves leather Mn Work 4",
    NSN: "8415-00-268-7870",
    qty: 1,
    remarks: "In black bag",
    additionalRemarks: "***************",
  },
  {
    sno: 38,
    name: "Coveralls Mechncs Lrg",
    NSN: "8415-01-534-7377",
    qty: 0,
    remarks: "***************",
    additionalRemarks: "",
  },
  {
    sno: 39,
    name: "Trousers cold weather",
    NSN: "8415-01-641-1701",
    qty: 1,
    remarks: "Vaccum Bag",
    additionalRemarks: "***************",
  },
  {
    sno: 40,
    name: "Parka Extreme cold weather",
    NSN: "8415-01-538-6300",
    qty: 1,
    remarks: "Vaccum Bag",
    additionalRemarks: "***************",
  },
  {
    sno: 41,
    name: "undershirt cold weather",
    NSN: "8415-01-641-1741",
    qty: 2,
    remarks: "Same as 49",
    additionalRemarks: "***************",
  },
  {
    sno: 42,
    name: "Drawers Cold weather",
    NSN: "8415-01-641-0912",
    qty: 2,
    remarks: "Same as 50",
    additionalRemarks: "***************",
  },
  {
    sno: 43,
    name: "trousers extreme cold weather",
    NSN: "8415-01-538-6704",
    qty: 1,
    remarks: "Same as 51",
    additionalRemarks: "***************",
  },
  {
    sno: 44,
    name: "shirt cold weather",
    NSN: "8415-01-641-1823",
    qty: 1,
    remarks: "Same as 52",
    additionalRemarks: "***************",
  },
  {
    sno: 45,
    name: "drawers cold weather",
    NSN: "8415-01-641-1272",
    qty: 1,
    remarks: "Same as 53",
    additionalRemarks: "***************",
  },
  {
    sno: 46,
    name: "Sleeping Bag",
    NSN: "8465-01-547-2697",
    qty: 1,
    remarks: "Same as 54",
    additionalRemarks: "***************",
  },
  {
    sno: 47,
    name: "Bivy Cover",
    NSN: "8465-01-547-2651",
    qty: 1,
    remarks: "Same as 55",
    additionalRemarks: "***************",
  },
  {
    sno: 48,
    name: "Patrol Sleeping Bag",
    NSN: "8465-01-547-2712",
    qty: 1,
    remarks: "Same as 56",
    additionalRemarks: "***************",
  },
  {
    sno: 49,
    name: "undershirt cold weather",
    NSN: "8415-01-641-1741",
    qty: 2,
    remarks: "Vaccum Bag",
    additionalRemarks: "1 Extra from basic training in boxes",
  },
  {
    sno: 50,
    name: "Drawers Cold weather",
    NSN: "8415-01-641-0912",
    qty: 2,
    remarks: "Vaccum Bag",
    additionalRemarks: "1 Extra from basic training in boxes",
  },
  {
    sno: 51,
    name: "Cold Weather Trouser",
    NSN: "8415-01-538-6704",
    qty: 1,
    remarks: "Vaccum Bag",
    additionalRemarks: "***************",
  },
  {
    sno: 52,
    name: "shirt cold weather (Waffle Top)",
    NSN: "8415-01-641-1823",
    qty: 1,
    remarks: "Vaccum Bag",
    additionalRemarks: "1 Extra from basic training in boxes",
  },
  {
    sno: 53,
    name: "Drawers Cold weather(waffle bottom)",
    NSN: "8415-01-641-1272",
    qty: 1,
    remarks: "Vaccum Bag",
    additionalRemarks: "1 Extra from basic training in boxes",
  },
  {
    sno: 54,
    name: "Sleeping Bag",
    NSN: "8465-01-547-2697",
    qty: 1,
    remarks: "SLEEPING BAG",
    additionalRemarks: "***************",
  },
  {
    sno: 55,
    name: "Bivy Cover",
    NSN: "8465-01-547-2651",
    qty: 1,
    remarks: "SLEEPING BAG",
    additionalRemarks: "***************",
  },
  {
    sno: 56,
    name: "Patrol Sleeping Bag",
    NSN: "8465-01-547-2712",
    qty: 1,
    remarks: "SLEEPING BAG",
    additionalRemarks: "***************",
  },
];

function File() {
  return (
    <div className="App">
      <div className="header">
        <img src={frame} className="App-logo" alt="logo" />
        <div className="titlebar">
          <span className="title">Bag One</span>
          <p className="description">/27c864d3-f24d-4cff-b7d3-c764ebea402d</p>
          <p className="descriptiontwo">
            /27c864d3-f24d-4cff-b7d3-c764ebea402d
          </p>
        </div>
      </div>
      <div className="container">
        <table>
          <tbody>
            <tr>
              <th></th>
            </tr>
            {listOfThings.map((item) => (
              <tr>
                <td>{item.sno}</td>
                <td>{item.name}</td>
                <td>{item.NSN}</td>
                <td>{item.qty}</td>
                <td>{item.remarks}</td>
                <td>{item.additionalRemarks}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default File;

import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>List of things packed in this</p>

        <a className="App-link" href="/u/27c864d3-f24d-4cff-b7d3-c764ebea402d">
          Bag One
        </a>

        <a className="App-link" href="/u/19850335-b81d-4dc1-96a7-037827fe9e98">
          Bag Two
        </a>
      </header>
    </div>
  );
}

export default App;
